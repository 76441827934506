import Vuex from "vuex";
import Vue from "vue";
import createPersistedState from "vuex-persistedstate";
import { user, getConfig } from "@/api/index.js";

Vue.use(Vuex);
let lang = process.env.VUE_APP_LANG;
console.log(lang);
const store = new Vuex.Store({
	state: {
		token: null,
		user: null,
		config: null,
		// lang: "th_TH", //泰国
		lang: lang, //越南
	},
	mutations: {
		setToken(state, token) {
			state.token = token;
		},
		setUser(state, user) {
			state.user = user;
		},
		setConfig(state, config) {
			state.config = config;
		},
		setLang(state, lang) {
			state.lang = lang;
		},
		logout(state) {
			state.token = null;
			state.user = null;
		},
	},
	actions: {
		async fetchUser({ commit }) {
			try {
				const userResponse = await user();
				commit("setUser", userResponse.data);
			} catch (error) {
				console.error(error);
			}
		},
		async fetchConfig({ commit }) {
			try {
				const configResponse = await getConfig();
				commit("setConfig", configResponse.data);
			} catch (error) {
				console.error(error);
			}
		},
	},
	plugins: [createPersistedState()],
});

export default store;

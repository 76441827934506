export default {
	万: "Triệu",
	确认: "xác nhận",
	取消: "hủy bỏ",
	账号: "tài khoản người dùng",
	密码: "mật khẩu",
	确认密码: "xác nhận mật khẩu",
	邀请码: "mã mời",
	公益榜: "bảng từ thiện",
	音乐榜: "bảng xếp hạng âm nhạc",
	电影榜: "bảng xếp hạng phim",
	财经榜: "bảng xếp hạng tài chính",
	直播榜: "bảng xếp hạng trực tiếp",
	密码错误: "Mật khẩu sai",
	选红色: "Chọn màu đỏ",
	选蓝色: "Chọn màu xanh",
	币单位: "VND",
	donation: {
		title: "Quyên góp",
		total_donations_amount: "Tổng số tiền quyên góp của nền tảng",
		my_donation_amount: "Số tiền quyên góp của tôi",
		donation_count: "Số lần quyên góp",
		num: "Lần",
		invitations_sent: "Mời bạn bè tham gia",
		donate_now_button: "Quyên góp ngay",
		donation_amount_input: "Nhập số tiền quyên góp",
	},
	tabbar: {
		home: "Trang chủ",
		welfare: "Bảng từ thiện",
		antifraud: "Chống gian lận",
		my: "Tôi",
	},
	withdraw: {
		current_account_balance: "Số dư tài khoản hiện tại",
		placeholder1: "Vui lòng nhập số tiền bạn muốn rút",
		bank_card_info: "Thông tin thẻ ngân hàng",
		tip1: "Chưa liên kết với thẻ ngân hàng, liên kết ngay bây giờ",
		receiving_bank: "Ngân hàng nhận thẻ",
		receiving_account: "Tài khoản nhận",
		receiving_name: "Tên người nhận",
		placeholder2: "Vui lòng nhập mật khẩu rút tiền",
		submit_application: "Xác nhận rút tiền",
		hint: "Lời khuyên hữu ích",
		withdraw_time: "Thời gian rút tiền",
		tip2: "Bạn chưa thiết lập mật khẩu rút tiền, chuyển đến cài đặt?",
		toast1: "Mật khẩu rút tiền phải là 6 chữ số",
		toast2: "Vui lòng liên kết thẻ ngân hàng trước tiên",
	},
	records: {
		phase: "Giai đoạn",
		push_red: "Khuyến nghị màu đỏ",
		push_blue: "Khuyến nghị màu xanh",
		odd: "Tỷ lệ cược",
		opened: "Đã mở",
		not_opened: "Chưa mở",
		result: "người dùng.kết  quả",
		red_victory: "Đỏ thắng",
		blue_victory: "Xanh thắng",
		red_profit: "Lợi nhuận bên đỏ",
		blue_profit: "Lợi nhuận bên xanh",
	},
	recharge: {
		placeholder1: "Vui lòng nhập số tiền cần nạp",
		placeholder2: "Vui lòng tải lên phiếu nạp tiền của bạn",
		upload_successfully: "Tải lên thành công",
		tip1: "Kích thước tệp tin không được vượt quá 6MB",
		submitted_successfully: "Nộp thành công",
		tip2: "Bạn đang có một yêu cầu nạp tiền đang được xem xét, vui lòng chờ đợi trong giây lát",
	},
	bank: {
		bank_card_management: "Quản lý thẻ ngân hàng",
		placeholder1: "Vui lòng nhập tên ngân hàng",
		placeholder2: "số tài khoản ngân hàng",
		placeholder3: "Vui lòng nhập tên người nhận",
		label1: "Tên ngân hàng",
		label2: "số tài khoản người nhận",
		label3: "Tên người nhận",
		add_successfully: "Thêm thành công",
		add_failure: "Thêm thất bại",
		card_number_error: "Số thẻ không đúng theo định dạng yêu cầu",
	},
	safety: {
		text1: "Thay đổi mật khẩu rút tiền",
		text2: "Vui lòng nhập mật khẩu cũ",
		text3: "Vui lòng nhập mật khẩu mới",
		text4: "Vui lòng xác nhận lại mật khẩu mới",
		modified_successfully: "Thay đổi thành công",
		old_password_error: "Mật khẩu cũ không chính xác",
		title: "Trung tâm phản bội",
	},
	register: {
		title: "Đăng ký người dùng",
		input_placeholder1: "Vui lòng nhập tên người dùng",
		input_placeholder2: "Vui lòng nhập mật khẩu",
		input_placeholder3: "Vui lòng nhập lại mật khẩu",
		input_placeholder4: "Vui lòng nhập mã giới thiệu",
		register: "Đăng ký",
		text1: "Đã có tài khoản?",
		to_login: "Đăng nhập ngay",
		toast1: "Mật khẩu nhập lại không khớp",
	},
	index: {
		title: "Lời bài hát",
		text1: "Chi tiết thông báo",
		period_time: "Đợi",
		deadline_participation: "Hạn cuối tham gia",
		closed: "Đóng",
		deadline: "Hạn cuối trao giải",
		min_bet_amount: "Số tiền cược tối thiểu",
		number_one: "Số mục",
		red_team: "Đội đỏ",
		blue_team: "Đội xanh",
		red_team_odds: "Tỷ lệ cược đội đỏ",
		blue_team_odds: "Tỷ lệ cược đội xanh",
		all: "Tất cả",
		get_involved_now: "Tham gia ngay",
		placeholder1: "Vui lòng nhập số tiền cược",
	},
	login: {
		title: "Vui lòng đăng nhập",
		tip1: "Vui lòng nhập tên người dùng",
		tip2: "Vui lòng nhập mật khẩu",
		forget_password: "Quên mật khẩu",
		submit: "Gửi",
		text1: "Chưa có tài khoản",
		to_register: "Đăng ký",
		change_login_password: "Thay đổi mật khẩu đăng nhập",
	},
	user: {
		withdraw_password: "Mật khẩu rút tiền",
		login_password: "Mật khẩu đăng nhập",
		account_security: "mật khẩu-bảo mật",
		rejected: "Bị từ chối",
		passed: "Đã thông qua",
		pending_review: "Đang chờ xét duyệt",
		state: "Tình trạng",
		time: "Thời gian",
		amount: "Số tiền",
		types: "Loại",
		withdraw_funds: "Rút tiền",
		recharge: "Nạp tiền",
		no_more: "Không còn nữa",
		loading: "Đang tải...",
		account: "Tài khoản",
		invitation_code: "Mã mời",
		balance: "Số dư",
		endowment: "Quỹ từ thiện",
		withdraw_cash_now: "rút tiền",
		go_recharge: "Nạp tiền",
		tiktok_shop: "Cửa hàng TikTok",
		security_center: "trung tâm bảo mật",
		bank_record: "Hồ sơ ngân hàng",
		transaction_records: "Hồ sơ giao dịch",
		bank_card: "Thẻ ngân hàng",
		language: "Ngôn ngữ",
		logout: "Đăng xuất",
		go_setting: "Đi tới Cài đặt"
	},
};

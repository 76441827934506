export default {
	万: "万",
	确认: "确认",
	取消: "取消",
	账号: "账号",
	密码: "密码",
	确认密码: "确认密码",
	邀请码: "邀请码",
	公益榜: "公益榜",
	音乐榜: "音乐榜",
	电影榜: "电影榜",
	财经榜: "财经榜",
	直播榜: "直播榜",
	密码错误: "密码错误",
	选红色: "选红色",
	选蓝色: "选蓝色",
	币单位: "￥",
	donation: {
		title: "捐赠",
		total_donations_amount: "平台总捐款",
		my_donation_amount: "我的捐款",
		donation_count: "捐款次数",
		num: "次",
		invitations_sent: "邀请好友参加",
		donate_now_button: "立即捐款",
		donation_amount_input: "请输入捐款金额",
	},
	tabbar: {
		home: "首页",
		welfare: "公益榜",
		antifraud: "反诈",
		my: "我的",
	},
	withdraw: {
		current_account_balance: "当前账户余额",
		placeholder1: "请输入提现金额",
		bank_card_info: "银行卡信息",
		tip1: "未绑定银行卡，立即绑定",
		receiving_bank: "收款银行卡",
		receiving_account: "收款人账号",
		receiving_name: "收款人姓名",
		placeholder2: "请输入提现密码",
		submit_application: "提交申请",
		hint: "温馨提示",
		withdraw_time: "提现时间",
		tip2: "您还未设置提现密码，是否前往设置？",
		toast1: "提现密码为6位数字",
		toast2: "请先绑定银行卡",
	},
	records: {
		phase: "期数",
		push_red: "推选红色",
		push_blue: "推选蓝色",
		odd: "赔率",
		opened: "已开奖",
		not_opened: "未开奖",
		result: "结果",
		red_victory: "红方胜",
		blue_victory: "蓝方胜",
		red_profit: "红方盈利",
		blue_profit: "蓝方盈利",
	},
	recharge: {
		placeholder1: "请输入充值金额",
		placeholder2: "请上传您的充值凭证",
		upload_successfully: "上传成功",
		tip1: "文件大小不能超过6m",
		submitted_successfully: "提交成功",
		tip2: "您有一笔充值正在审核中，请耐心等待",
	},
	bank: {
		bank_card_management: "银行卡管理",
		placeholder1: "请输入收款银行卡",
		placeholder2: "请输入收款人账号",
		placeholder3: "请输入收款人姓名",
		label1: "收款银行卡",
		label2: "收款人账号",
		label3: "收款人姓名",
		add_successfully: "添加成功",
		add_failure: "添加失败",
	},
	safety: {
		text1: "修改提现密码",
		text2: "请输入原密码",
		text3: "请输入新密码",
		text4: "请确认新密码",
		modified_successfully: "修改成功",
		old_password_error: "原密码错误",
		title: "反诈中心",
	},
	register: {
		title: "用户注册",
		input_placeholder1: "请输入账号",
		input_placeholder2: "请输入密码",
		input_placeholder3: "请再次输入密码",
		input_placeholder4: "请输入邀请码",
		register: "注册",
		text1: "已有账号",
		to_login: "立即登录",
		toast1: "两次密码输入不一致",
	},
	index: {
		title: "爱在前进",
		text1: "公告详情",
		period_time: "期",
		deadline_participation: "截止参与",
		closed: "已封盘",
		deadline: "截止开奖",
		min_bet_amount: "最低投注金额",
		number_one: "号榜",
		red_team: "红队",
		blue_team: "蓝队",
		red_team_odds: "红队赔率",
		blue_team_odds: "蓝队赔率",
		all: "全部",
		get_involved_now: "立即参与",
		placeholder1: "请输入投注金额",
	},
	login: {
		title: "欢迎登录",
		tip1: "请输入账号",
		tip2: "请输入密码",
		forget_password: "忘记密码",
		submit: "提交",
		text1: "还没有账号",
		to_register: "去注册",
		change_login_password: "修改登录密码",
	},
	user: {
		withdraw_password: "提现密码",
		login_password: "登录密码",
		account_security: "账号安全",
		rejected: "已拒绝",
		passed: "已通过",
		pending_review: "待审核",
		state: "状态",
		time: "时间",
		amount: "金额",
		types: "类型",
		withdraw_funds: "提现",
		recharge: "充值",
		no_more: "没有更多了",
		loading: "加载中...",
		account: "账号",
		invitation_code: "邀请码",
		balance: "余额",
		endowment: "捐赠",
		withdraw_cash_now: "立即提现",
		go_recharge: "去充值",
		tiktok_shop: "TikTok商城",
		security_center: "安全中心",
		bank_record: "充提记录",
		transaction_records: "交易记录",
		bank_card: "银行卡",
		language: "语言",
		logout: "登出",
		go_setting: "去设置"
	},
};

<template>
    <div id="app">
        <router-view />

        <!--    <a :href="link" target="_blank" class="customer-service" v-if="isTabs">-->
        <!--      <img src="./assets/customer_service.png" alt="">-->
        <!--    </a>-->
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    name: 'App',
    computed: {
        ...mapState(['config', 'user']),
        link() {
            return this.config?.customer_service_url?.value
        },
        routerPath() {
            return this.$route.path
        },
        isTabs() {
            const pathArr = ['/index', '/welfare', '/safety', '/my']
            return pathArr.includes(this.$route.path)
        }
    },
    methods: {
        ...mapActions(['fetchConfig']),
    },
    created() {
        this.fetchConfig()
        if (this.user) {
            // eslint-disable-next-line no-undef
            const myDate = new Date();//获取系统当前时间
            const timestr = myDate.getFullYear() + (myDate.getMonth() + 1) + myDate.getDate();
            window.ssq.push('setLoginInfo', {
                user_id: this.user?.invitation_code + timestr, // 加密后的用户 id, 必填
                user_name: this.user?.username, // 用户名， 必填
                // language: 'ru-RU', // 插件语言， 选填
                // phone: '1592014xxxx', // 手机， 选填
                // email: 'test@test', // 邮箱， 选填
                // description: '套餐B\n客户端\n收费客户', // 描述， 选填
                // label_names: ['标签值1','标签值2'], // 标签值的名称，为覆盖方式，仅支持传系统已创建的标签值， 选填
            });
        }
    }
}
</script>

<style>
.mb-12 {
    margin-bottom: 12px;
}
.container {
    padding: 12px;
}
.px-24 {
    padding: 0 24px;
}
.card {
    margin-bottom: 12px;
    padding: 12px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 8px 12px #ebedf0;
}
.mt-0 {
    margin-top: 0 !important;
}
.fs-16 {
    font-size: 16px;
}
.align-items__center {
    align-items: center;
}
.overflow-hidden {
    overflow: hidden;
}
.flex {
    display: flex;
}
.flex-1 {
    flex: 1;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body {
    font-size: 14px;
    background: #f7f8fa;
    -webkit-font-smoothing: antialiased;
}
input {
    border: none;
    background: transparent;
    outline: none;
}
.customer-service {
    position: fixed;
    right: 12px;
    bottom: 50px;
    z-index: 999;
}
.customer-service img {
    width: 60px;
    height: 60px;
    object-fit: contain;
}
@media only screen and (min-width: 750px) {
    .van-list__loading .van-loading__text {
        font-size: 18px;
    }
}
</style>

import { post, get, upload } from "@/api/http.js";

export const register = (data) => post("/api/register", data);
export const login = (data) => post("/api/login", data);
export const user = () => get("/api/user");
export const updatePayPassword = (data) =>
	post("/api/update_pay_password", data);
export const updateLoginPassword = (data) =>
	post("/api/update_login_password", data);
export const recharge = (data) => post("/api/recharge", data);
export const uploadImg = (data) => upload("/api/uploadImg", data);
export const addBankCard = (data) => post("/api/add_bank_card", data);
export const withdraw = (data) => post("/api/withdraw", data);
export const betCreate = (data) => post("/api/bet_create", data);
export const donate = (data) => post("/api/donate", data);
export const getBankCard = () => get("/api/get_bank_card");
export const getConfig = () => get("/api/get_config");
export const logout = () => get("/api/logout");
export const donationData = () => get("/api/donationData");
export const getAnnouncements = () => get("/api/getAnnouncements");
export const getCategory = () => get("/api/get_category");
export const withdrawList = (params) => get("/api/withdraw_list", { params });
export const getAnnouncementDetail = (params) =>
	get("/api/getAnnouncementDetail", { params });
export const quizList = (params) => get("/api/quiz_list", { params });
export const betRecord = (params) => get("/api/bet_record", { params });
export const greeting = (lang) => get(`/api/greeting/${lang}`);
export const getArticleByCategoryId = (params) =>
	get("/api/get_article_by_category_id", { params });
export const verifyPass = (data) => post("/api/verify_pass", data);

import Vue from "vue";
import Router from "vue-router";
import store from "./store";

Vue.use(Router);

const routes = [
	{
		path: "/",
		redirect: "/index",
	},
	{
		path: "/login",
		component: () => import("./view/login"),
		meta: {
			// title: '登录',
			requiresAuth: false,
		},
	},
	{
		path: "/register",
		component: () => import("./view/register"),
		meta: {
			// title: '注册',
			requiresAuth: false,
		},
	},
	{
		path: "/index",
		component: () => import("./view/index"),
		meta: {
			// title: '首页',
			requiresAuth: true,
		},
	},
	{
		path: "/welfare",
		component: () => import("./view/welfare"),
		meta: {
			// title: '公益榜',
			requiresAuth: true,
		},
	},
	{
		path: "/safety",
		component: () => import("./view/safety"),
		meta: {
			// title: '反诈',
			requiresAuth: true,
		},
	},
	{
		path: "/my",
		component: () => import("./view/my"),
		meta: {
			// title: '我的',
			requiresAuth: true,
		},
		children: [{
			path: "settings",
			component: () => import("./view/user/settings"),
			meta: {
				// title: '账户安全',
				requiresAuth: true,
				myIndex: 2
			},
		},
		{
			path: "login_password",
			component: () => import("./view/safety/login_password"),
			meta: {
				// title: '修改登录密码',
				requiresAuth: true,
				myIndex: 2
			},
		},
		{
			path: "cash_password",
			component: () => import("./view/safety/cash_password"),
			meta: {
				// title: '修改提现密码',
				requiresAuth: true,
				myIndex: 2
			},
		},
		{
			path: "bank_record",
			component: () => import("./view/user/bank_record"),
			meta: {
				// title: '充提记录',
				requiresAuth: true,
				myIndex: 3
			},
		},
		{
			path: "transaction_record",
			component: () => import("./view/user/transaction_record"),
			meta: {
				// title: '交易记录',
				requiresAuth: true,
				myIndex: 4
			},
		},
		{
			path: "bank_card",
			component: () => import("./view/user/bank_card"),
			meta: {
				// title: '银行卡管理',
				requiresAuth: true,
				myIndex: 5
			},
		},
		{
			path: "withdraw_funds",
			component: () => import("./view/user/withdraw_funds"),
			meta: {
				// title: '提现',
				requiresAuth: true,
				myIndex: 2
			},
		},
		{
			path: "recharge",
			component: () => import("./view/user/recharge"),
			meta: {
				// title: '充值',
				requiresAuth: true,
				myIndex: 2
			},
		},
		{
			path: "endowment",
			component: () => import("./view/my/endowment"),
			meta: {
				// title: '捐赠',
				requiresAuth: true,
				myIndex: 2
			},
		},
		]
	},
	{
		path: "/settings",
		component: () => import("./view/user/settings"),
		meta: {
			// title: '账户安全',
			requiresAuth: true,
		},
	},
	{
		path: "/login_password",
		component: () => import("./view/safety/login_password"),
		meta: {
			// title: '修改登录密码',
			requiresAuth: true,
		},
	},
	{
		path: "/cash_password",
		component: () => import("./view/safety/cash_password"),
		meta: {
			// title: '修改提现密码',
			requiresAuth: true,
		},
	},
	{
		path: "/bank_card",
		component: () => import("./view/user/bank_card"),
		meta: {
			// title: '银行卡管理',
			requiresAuth: true,
		},
	},
	{
		path: "/withdraw_funds",
		component: () => import("./view/user/withdraw_funds"),
		meta: {
			// title: '提现',
			requiresAuth: true,
		},
	},
	{
		path: "/bank_record",
		component: () => import("./view/user/bank_record"),
		meta: {
			// title: '充提记录',
			requiresAuth: true,
		},
	},
	{
		path: "/transaction_record",
		component: () => import("./view/user/transaction_record"),
		meta: {
			// title: '交易记录',
			requiresAuth: true,
		},
	},
	{
		path: "/recharge",
		component: () => import("./view/user/recharge"),
		meta: {
			// title: '充值',
			requiresAuth: true,
		},
	},
	{
		path: "/announcement_detail",
		component: () => import("./view/index/announcement_detail"),
		meta: {
			// title: '公告详情',
			requiresAuth: true,
		},
	},
	{
		path: "/endowment",
		component: () => import("./view/my/endowment"),
		meta: {
			// title: '捐赠',
			requiresAuth: true,
		},
	},
];

// add route path
routes.forEach((route) => {
	route.path = route.path || "/" + (route.name || "");
});

const router = new Router({ routes });

router.beforeEach((to, from, next) => {
	const title = to.meta && to.meta.title;
	if (title) {
		document.title = title;
	}
	// 检查该路由是否需要登录权限
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		// 这里假设你将token存储在了localStorage或Vuex store中，根据你的需要调整
		const token = store.state.token;
		if (!token) {
			// 如果没有token，则跳转到登录页面
			next({
				path: "/login",
				// 将要访问的路径作为参数，便于登录后可以直接跳转到该路径
				query: { redirect: to.fullPath },
			});
		} else {
			// 如果有token，则直接访问
			next();
		}
	} else {
		// 如果不需要登录权限，则直接访问
		next();
	}
});

export { router };
